import React from "react";

export const NephrologyIcon = () => {
  return (
    <svg
      width="43"
      height="38"
      viewBox="0 0 43 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1717_4328)">
        <path
          d="M30.8002 17.7667H26.863C26.7294 17.7667 26.6012 17.8194 26.5067 17.9132C26.4122 18.0071 26.3591 18.1343 26.3591 18.267V22.4898H22.1061C21.9725 22.4898 21.8443 22.5425 21.7498 22.6363C21.6553 22.7302 21.6022 22.8574 21.6022 22.9901V26.8994C21.6022 27.0321 21.6553 27.1593 21.7498 27.2532C21.8443 27.347 21.9725 27.3997 22.1061 27.3997H26.3591V31.6241C26.3591 31.7568 26.4122 31.8841 26.5067 31.9779C26.6012 32.0718 26.7294 32.1245 26.863 32.1245H30.8002C30.9338 32.1245 31.062 32.0718 31.1565 31.9779C31.251 31.8841 31.3041 31.7568 31.3041 31.6241V27.3997H35.5571C35.6907 27.3997 35.8189 27.347 35.9134 27.2532C36.0079 27.1593 36.061 27.0321 36.061 26.8994V22.9901C36.061 22.8574 36.0079 22.7302 35.9134 22.6363C35.8189 22.5425 35.6907 22.4898 35.5571 22.4898H31.3041V18.267C31.3041 18.1343 31.251 18.0071 31.1565 17.9132C31.062 17.8194 30.9338 17.7667 30.8002 17.7667V17.7667ZM35.0532 23.4905V26.399H30.8002C30.6666 26.399 30.5384 26.4518 30.4439 26.5456C30.3494 26.6394 30.2963 26.7667 30.2963 26.8994V31.1238H27.3669V26.8994C27.3669 26.7667 27.3138 26.6394 27.2193 26.5456C27.1248 26.4518 26.9967 26.399 26.863 26.399H22.61V23.4905H26.863C26.9967 23.4905 27.1248 23.4377 27.2193 23.3439C27.3138 23.2501 27.3669 23.1228 27.3669 22.9901V18.7674H30.2963V22.9901C30.2963 23.1228 30.3494 23.2501 30.4439 23.3439C30.5384 23.4377 30.6666 23.4905 30.8002 23.4905H35.0532Z"
          stroke-width="0.2"
          className="group-hover:fill-[#F2505D] fill-[#162D6D] stroke-[#162D6D] group-hover:stroke-[#F2505D] transition-colors duration-300 ease-linear"
        />
        <path
          d="M42.9597 9.99525L38.7605 0.300541C38.7216 0.210419 38.657 0.133609 38.5746 0.0796468C38.4922 0.0256849 38.3956 -0.00304984 38.2969 -0.00299063H4.70312C4.6044 -0.00304984 4.50784 0.0256849 4.42542 0.0796468C4.343 0.133609 4.27836 0.210419 4.23953 0.300541L0.0403121 9.99525C0.0155251 10.058 0.00188609 10.1246 0 10.192V12.9472C0 16.0059 4.0934 17.1166 5.75293 14.615C6.97574 16.4628 9.78418 16.4595 11.002 14.615C12.2248 16.4628 15.0332 16.4595 16.251 14.615C17.4738 16.4628 20.2822 16.4612 21.5 14.615C22.7228 16.4628 25.5312 16.4595 26.749 14.615C27.9718 16.4628 30.7803 16.4595 31.998 14.615C33.2024 16.4345 36.0075 16.4879 37.2471 14.615C38.91 17.1283 43 15.9975 43 12.9472V10.1954C42.9984 10.1269 42.9848 10.0591 42.9597 9.99525V9.99525ZM5.03906 1.001H37.9609L41.7268 9.69506H1.26984L5.03906 1.001ZM1.00781 12.9505V10.6957H5.24902V12.9505C5.24902 15.6823 1.00781 15.6807 1.00781 12.9505ZM6.25684 12.9505V10.6957H10.498V12.9505C10.498 15.6823 6.25684 15.6807 6.25684 12.9505ZM11.5059 12.9505V10.6957H15.7471V12.9505C15.7471 15.6823 11.5059 15.6807 11.5059 12.9505ZM16.7549 12.9505V10.6957H20.9961V12.9505C20.9961 15.6823 16.7549 15.6807 16.7549 12.9505ZM22.0039 12.9505V10.6957H26.2451V12.9505C26.2451 15.6823 22.0039 15.6807 22.0039 12.9505ZM27.2529 12.9505V10.6957H31.4941V12.9505C31.4941 15.6823 27.2529 15.6807 27.2529 12.9505ZM32.502 12.9505V10.6957H36.7432V12.9505C36.7432 15.6823 32.502 15.6807 32.502 12.9505ZM41.9922 12.9505C41.9922 15.6823 37.751 15.6807 37.751 12.9505V10.6957H41.9922V12.9505Z"
          stroke-width="0.2"
          className="group-hover:fill-[#F2505D] fill-[#162D6D] stroke-[#162D6D] group-hover:stroke-[#F2505D] transition-colors duration-300 ease-linear"
        />
        <path
          d="M16.2507 20.6219H10.0123C9.87868 20.6219 9.75051 20.6746 9.65601 20.7684C9.56151 20.8623 9.50842 20.9895 9.50842 21.1222V30.0948C9.50842 30.2275 9.56151 30.3547 9.65601 30.4486C9.75051 30.5424 9.87868 30.5951 10.0123 30.5951H16.2507C16.3843 30.5951 16.5125 30.5424 16.607 30.4486C16.7015 30.3547 16.7546 30.2275 16.7546 30.0948V21.1222C16.7546 20.9895 16.7015 20.8623 16.607 20.7684C16.5125 20.6746 16.3843 20.6219 16.2507 20.6219V20.6219ZM15.7468 29.5945H10.5162V21.6225H15.7468V29.5945Z"
          stroke-width="0.2"
          className="group-hover:fill-[#F2505D] fill-[#162D6D] stroke-[#162D6D] group-hover:stroke-[#F2505D] transition-colors duration-300 ease-linear"
        />
        <path
          d="M41.1722 37.001H39.7445V17.2447C39.7445 17.112 39.6914 16.9847 39.5969 16.8909C39.5024 16.797 39.3742 16.7443 39.2406 16.7443C39.107 16.7443 38.9788 16.797 38.8843 16.8909C38.7898 16.9847 38.7367 17.112 38.7367 17.2447V36.9843H19.38V18.267C19.38 18.1343 19.3269 18.007 19.2324 17.9132C19.1379 17.8194 19.0097 17.7667 18.8761 17.7667H7.49451C7.36086 17.7667 7.23269 17.8194 7.13819 17.9132C7.04369 18.007 6.9906 18.1343 6.9906 18.267V36.9843H4.26279V17.4331C4.26279 17.3004 4.2097 17.1732 4.1152 17.0793C4.0207 16.9855 3.89253 16.9328 3.75888 16.9328C3.62524 16.9328 3.49707 16.9855 3.40257 17.0793C3.30807 17.1732 3.25498 17.3004 3.25498 17.4331V37.001H1.86755C1.73391 37.001 1.60574 37.0537 1.51124 37.1475C1.41674 37.2414 1.36365 37.3686 1.36365 37.5013C1.36365 37.634 1.41674 37.7613 1.51124 37.8551C1.60574 37.9489 1.73391 38.0016 1.86755 38.0016H41.1722C41.3059 38.0016 41.4341 37.9489 41.5286 37.8551C41.6231 37.7613 41.6761 37.634 41.6761 37.5013C41.6761 37.3686 41.6231 37.2414 41.5286 37.1475C41.4341 37.0537 41.3059 37.001 41.1722 37.001ZM7.99841 18.7673H18.3722V36.9843H7.99841V18.7673Z"
          stroke-width="0.2"
          className="group-hover:fill-[#F2505D] fill-[#162D6D] stroke-[#162D6D] group-hover:stroke-[#F2505D] transition-colors duration-300 ease-linear"
        />
      </g>
      <defs>
        <clipPath id="clip0_1717_4328">
          <rect width="43" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
